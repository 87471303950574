import React from 'react'
import Layout from '../layout/layout'
import technicalArticles from '../data/technicalArticles'
import { TechnicalArticleList } from '../components/technicalArticles'

const TechnicalArticlesPage = () => (
  <Layout
    pageTitle="技术文章"
    pageSubTitle="发布在 IBM developerWorks 上的技术文章"
  >
    <div className="container clearfix mb-4">
      <TechnicalArticleList articles={technicalArticles} />
    </div>
  </Layout>
)

export default TechnicalArticlesPage
